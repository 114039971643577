import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GymStatusInfo } from '@/api/main';
import { gymStatusesSelector } from '@/store/selector/gymSelector';
import { onGetGymStatuses } from '@/store/slice/gymSlice';

export const useGymStatus = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const gymsStatuses: GymStatusInfo[] = useSelector(gymStatusesSelector);

  const [selectedGymStatus, setSelectedGymStatus] = useState<GymStatusInfo | undefined>(undefined);

  useEffect(() => {
    const gymId = router.query.gym_id;
    const gymStatus = gymsStatuses.find((x) => x.id === Number(gymId));
    setSelectedGymStatus(gymStatus);
  }, [router.query.gym_id, gymsStatuses]);

  useEffect(() => {
    dispatch(onGetGymStatuses());
  }, [dispatch]);

  return {
    selectedGymStatus,
  };
};
