import styled from 'styled-components';

import { Layout } from '@/components/templates/Layout';
import { useGymStatus } from '@/hooks/useGymStatus';
import { numberConverter } from '@/utils/numberConverter';

const Gymx = () => {
  const { selectedGymStatus } = useGymStatus();

  return (
    <Layout>
      <Container>
        <NotificationContent>
          <NotificationHeader>お知らせ</NotificationHeader>
          <NotificationDetail>
            <NotificationDetailTitle>管理画面がLifeFit Dashboardとして新しくなりました。</NotificationDetailTitle>
            <NotificationDetailContent>
              管理画面が、より使いやすく進化しました。
              <br />
              不明点・要望がありましたらOwner&apos;s Slackのチャンネルでご連絡お願いします。
            </NotificationDetailContent>
          </NotificationDetail>
        </NotificationContent>
        <Summary>
          <SummaryContentArea>
            <SummaryContentWrap>
              <SummaryContent>
                <SummaryTitle color="#08222C">定期利用ユーザ数</SummaryTitle>
                <SummaryValueWrap>
                  <SummaryValue>{numberConverter(selectedGymStatus?.subscribeUserCount)}</SummaryValue>
                  <SummaryUnit>人</SummaryUnit>
                </SummaryValueWrap>
              </SummaryContent>
            </SummaryContentWrap>
          </SummaryContentArea>
          <SummaryContentArea>
            <SummaryContentWrap isMultiple>
              <SummaryContent>
                <SummaryTitle color="#4771E0">定期利用購入数</SummaryTitle>
                <SummaryValueWrap>
                  <SummaryValue>{numberConverter(selectedGymStatus?.todaySubscribePurchaseCount)}</SummaryValue>
                  <SummaryUnit>人</SummaryUnit>
                </SummaryValueWrap>
              </SummaryContent>
              <SummarySeparater />
              <SummaryContent>
                <SummaryTitle color="#4771E0">定期利用利用数</SummaryTitle>
                <SummaryValueWrap>
                  <SummaryValue>{numberConverter(selectedGymStatus?.todaySubscribeUsedCount)}</SummaryValue>
                  <SummaryUnit>人</SummaryUnit>
                </SummaryValueWrap>
              </SummaryContent>
            </SummaryContentWrap>
          </SummaryContentArea>
          <SummaryContentArea>
            <SummaryContentWrap isMultiple>
              <SummaryContent>
                <SummaryTitle color="#65CED9">都度利用購入数</SummaryTitle>
                <SummaryValueWrap>
                  <SummaryValue>{numberConverter(selectedGymStatus?.todayTemporaryUsePurchaseCount)}</SummaryValue>
                  <SummaryUnit>人</SummaryUnit>
                </SummaryValueWrap>
              </SummaryContent>
              <SummarySeparater />
              <SummaryContent>
                <SummaryTitle color="#65CED9">都度利用利用数</SummaryTitle>
                <SummaryValueWrap>
                  <SummaryValue>{numberConverter(selectedGymStatus?.todayTemporaryUseUsedCount)}</SummaryValue>
                  <SummaryUnit>人</SummaryUnit>
                </SummaryValueWrap>
              </SummaryContent>
            </SummaryContentWrap>
          </SummaryContentArea>
          <SummaryContentArea>
            <SummaryContentWrap>
              <SummaryContent>
                <SummaryTitle color="#E66464">昨日定期利用期限切れ</SummaryTitle>
                <SummaryValueWrap>
                  <SummaryValue>{numberConverter(selectedGymStatus?.yesterdaySubscribeExpiredCount)}</SummaryValue>
                  <SummaryUnit>件</SummaryUnit>
                </SummaryValueWrap>
              </SummaryContent>
            </SummaryContentWrap>
          </SummaryContentArea>
        </Summary>
      </Container>
    </Layout>
  );
};

export default Gymx;

const Container = styled('div')`
  padding: 16px 8px;
  ${({ theme }) => theme.breakpoints.lg`
    padding: 24px 16px;
  `};
`;

const NotificationContent = styled('div')`
  widht: 100%;
  background: #ffffff;
  border: 2px solid #4771e0;
  border-radius: 10px;
  overflow: hidden;
`;

const NotificationHeader = styled('div')`
  height: 48px;
  color: #ffffff;
  background: #4771e0;
  line-height: 48px;
  font-weight: 700;
  font-size: 14px;
  padding: 0 24px;
`;

const NotificationDetail = styled('div')`
  padding: 24px 24px 32px 24px;
`;

const NotificationDetailTitle = styled('div')`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #4771e0;
`;

const NotificationDetailContent = styled('div')`
  font-size: 12px;
  color: #000000;
`;

const Summary = styled('div')`
  widht: 100%;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px 24px;
  justify-content: space-around;
  margin-top: 16px;
  display: grid;
  column-gap: 24px;
  row-gap: 16px;
  ${({ theme }) => theme.breakpoints.base`
    grid-template-columns: repeat(1, 5fr);
  `}
  ${({ theme }) => theme.breakpoints.lg`
  grid-template-columns: repeat(2, 2fr);
  grid-template-columns: 1fr 1fr;
`}
    ${({ theme }) => theme.breakpoints.xl`
    grid-template-columns: repeat(6, 1fr);
    grid-template-columns: 1fr 2fr 2fr 1fr;
    column-gap: 24px;
  `};
`;

const SummaryContentArea = styled.div<{ isMultiple?: boolean }>`
  display: flex;
  justify-content: center;
  background: #f4f4f4;
  width: 100%;
`;

const SummaryContentWrap = styled.div<{ isMultiple?: boolean }>`
  background: #f4f4f4;
  border-radius: 2px;
  padding: 16px;
  width: ${({ isMultiple }) => (isMultiple ? '300px' : '160px')};
  display: ${({ isMultiple }) => (isMultiple ? 'flex' : 'block')};
  max-height: 80px;
`;

const SummaryContent = styled('div')``;

const SummarySeparater = styled('div')`
  width: 1px;
  height: 40px;
  background: #4771e0;
  margin: auto;
`;

const SummaryTitle = styled.div<{ color: string }>`
  color: ${({ color }) => `${color}`};
  font-weight: 700;
  font-size: 16px;
  text-align: center;
`;

const SummaryValue = styled('div')`
  font-weight: 900;
  font-size: 40px;
  color: #000000;
  display: flex;
  align-items: bottom;
`;

const SummaryValueWrap = styled('div')`
  display: flex;
  align-items: end;
  justify-content: center;
`;

const SummaryUnit = styled('div')`
  font-weight: 900;
  font-size: 18px;
  margin-left: 4px;
  color: #000000;
`;
